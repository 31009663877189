import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * EVENT API endpoint for email templates
 * @constant {string} EVENT_EMAIL_TEMPLATES_ENDPOINT
 * @private
 */
const EVENT_EMAIL_TEMPLATES_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/emailTemplates`;


/**
 * Event API endpoint to load the users list
 * @constant {string} SEND_EMAILS_ENDPOINT
 * @private
 */
const SEND_EMAILS_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/email/template/{{templateExtId}}`;

/**
 * The number of content per page in the emailing list
 * @constant {Number} EMAILS_PER_PAGE
 * @private
 */
const EMAILS_PER_PAGE = 15;

/**
 * Event API endpoint to load the scheduled emails
 * @constant {string} SCHEDULED_EMAILS_ENDPOINT
 * @private
 */
const SCHEDULED_EMAILS_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/mailings`;

/**
 * API endpoint to get the list of scheduled emails timestamps
 * @constant {string} SCHEDULED_EMAILS_TIMESTAMP_ENDPOINT
 * @private
 */
const SCHEDULED_EMAILS_TIMESTAMP_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/scheduled-mailings`;

/**
 * Event API endpoint to delete a mailing
 * @constant {string} DELETE_MAILING_ENDPOINT
 * @private
 */
const DELETE_MAILING_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/mailings/{{mailingId}}`;

/**
 * API endpoint to get the user count for a target group
 * @constant {string} SEND_EMAILS_ENDPOINT
 * @private
 */
const EMAIL_PAX_COUNT_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/email/target/{{targetGroup}}`;

/**
 * API endpoint to get the user count for custom targetting
 * @constant {string} EMAIL_PAX_CUSTOM_COUNT_ENDPOINT
 * @private
 */
const EMAIL_PAX_CUSTOM_COUNT_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/email/targets`;

/**
 * Provide service related to Email Templates
 *
 * @example
 * import Emails from 'libs/services/content-hub/emails';
 * ...
 * const emails = new Emails();
 */
export default class EmailsService extends BaseService {

    /**
     * Getter to expose EMAILS_PER_PAGE from an instantiated service.
     */
    get EMAILS_PER_PAGE() {
        return EMAILS_PER_PAGE;
    }

    /**
     * Gets the content hub emails template' list
     *
     * @param {string} eventId the ID of the content hub to get the list for
     * @param {object} [obtions]
     * @param {boolean} [params.onlyActive=false] returns only active templates
     * @param {boolean} [params.cached=true] returns a cached version or not
     *
     * @returns {Promise<EmailTemplate[]>} the list of email tempaltes
     */
    async getEmailTemplates(eventId, { onlyActive = false, cached = true } = {}) {
        const url = this.buildUrl(EVENT_EMAIL_TEMPLATES_ENDPOINT, { eventId });

        let data;
        if (cached) {
            ({ data } = await this.getCached(url));
        } else {
            ({ data } = await this.getCached(url, {}, 0));
        }

        if (!onlyActive) return data;

        return data.filter(template => !template.is_inactive);
    }

    /**
     * Send emails to specific users or to a targeted group of users
     *
     * @param {string} eventId the ID of the content hub to get the templates for
     * @param {string} templateExtId the fp_ext_id of the email template
     * @param {string} targetGroup the targeted group of users to send emails to
     * @param {{ targets: object[], exceptions: object[]}} targeting the custom targets to send emails to
     * @param {unknown[]} recipientsExtIds the fp_ext_id of the users to send emails to
     * @param {Number} scheduleTime teh timestamp for email scheduling if any
     * @param {Object} templateVariables the variables to replace in the email template
     *
     * @returns {Promise<Object>} result
     */
    async sendEmails(eventId, templateExtId, targetGroup, targeting, recipientsExtIds, scheduleTime, templateVariables) {
        const url = this.buildUrl(SEND_EMAILS_ENDPOINT, { eventId, templateExtId });
        const { targets, exceptions } = targeting || {};
        const params = {
            fp_ext_ids: recipientsExtIds,
            scheduled_at: scheduleTime,
            targetGroup,
            targets,
            exceptions,
            templateVariables
        };
        const { data } = await this.post(url, params);
        return data;
    }

    /**
     * Gets the number of pax matching a given target
     *
     * @param {string} eventId
     * @param {string|{ targets: object[], exceptions: object[]}} targeting
     *
     * @returns {Promise<Number>}
     */
    getAffectedPaxCount(eventId, targeting) {
        if (typeof targeting === 'string') {
            return this.getTargetPaxCount(eventId, targeting);
        }

        return this.getCustomTargetPaxCount(eventId, targeting);
    }

    /**
     * Gets the number of pax matching a given target
     * @param {string} eventId
     * @param {string} targetGroup
     * @returns {Promise<Number>}
     */
    async getTargetPaxCount(eventId, targetGroup) {
        const url = this.buildUrl(EMAIL_PAX_COUNT_ENDPOINT, { eventId, targetGroup });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Gets the number of pax matching a given target
     * @param {string} eventId
     * @param {{ targets: object[], exceptions: object[]}} targeting
     * @returns {Promise<Number>}
     */
    async getCustomTargetPaxCount(eventId, targeting) {
        const url = this.buildUrl(EMAIL_PAX_CUSTOM_COUNT_ENDPOINT, { eventId });
        const { data } = await this.post(url, { ...targeting });
        return data;
    }

    /**
     * Loads the scheduled emails for a given page
     *
     * @param {string} eventId the ID of the event
     * @param {number} [pageNumber=1] the page number we're looking to display
     * @param {string} [search] an optional search word to use for filtering the list
     *
     * @returns {Promise<{total: number, items: ScheduledEmail[]}>}
     */
    async getScheduledEmails(eventId, pageNumber = 1, search) {
        const url = this.buildUrl(SCHEDULED_EMAILS_ENDPOINT, { eventId });
        const { data } = await this.get(url, { params:
            {
                limit: EMAILS_PER_PAGE,
                skip: (pageNumber - 1) * EMAILS_PER_PAGE,
                search
            }
        });
        return data;
    }

    /**
    * Loads the scheduled emails for a given page
    *
    * @param {string} eventId the ID of the event
    *
    * @returns {Promise<ScheduledEmail[]>}
    */
    async getScheduledEmailsTimestamps(eventId) {
        const url = this.buildUrl(SCHEDULED_EMAILS_TIMESTAMP_ENDPOINT, { eventId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Deletes the given mailing on the given event
     *
     * @param {string} eventId the ID of the event
     * @param {string} mailingId the ID of the mailing to delete
     *
     * @returns {Promise<import('axios').AxiosResponse>}
     */
    deleteMailing(eventId, mailingId) {
        const url = this.buildUrl(DELETE_MAILING_ENDPOINT, { eventId, mailingId });
        return this.delete(url);
    }
}
