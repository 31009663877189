import BaseService from './base-service';

// Utils
import { hasPermission, matchesRoles, getOrgsByFeatureFlag } from 'libs/utils/permissions';
import { sortByOrder } from 'libs/utils/collections';
import { deepExtend, deepExtendConcat } from 'libs/utils/objects';
import { getRouteHref } from 'libs/utils/modules';
import { get, isEmpty, isEqual } from 'lodash';

// Constants
import { API_BASE_PATH, TEMPLATE_URL_MAPPING, CONTROLLER_NAME_MAPPING } from 'libs/utils/constants';

/**
 * Blacklisted modules
 * These are the modules of packages that we merged back to BSTG
 * Files from these modules won't be loaded
 * @constant {String[]} MODULES_BLACKLIST
 */
const MODULES_BLACKLIST = ['modal-help', 'metadata_filter', 'metadata_selector', 'theme-editor', 'files'];

/**
 * Blueprint document API endpoint. Interpolations: `{{eventId}}`.
 * @constant {String} BLUEPRINT_DOCUMENT_ENDPOINT
 */
export const BLUEPRINT_DOCUMENT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/docbyid/blueprint`;

/**
 * Compile blueprint API endpoint. Interpolations: `{{eventId}}`.
 * @constant {String} COMPILE_BLUEPRINT_ENDPOINT
 */
export const COMPILE_BLUEPRINT_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/compile/blueprint`;

/**
 * @constant {String} FP_TYPE_LISTING_PATH FP Type specific backstage path. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const FP_TYPE_LISTING_PATH = '/event/{{eventId}}/{{fpType}}s';

// Default blueprint object
import DEFAULT_ROUTES from './default-routes';

/**
 * Provides utils for blueprint modules.
 *
 * @example
 * import BlueprintService from 'libs/services/blueprint';
 * ...
 * const blueprint = new BlueprintService();
 */
export default class BlueprintService extends BaseService {

    /**
     * Creates a new BlueprintService instance.
     */
    constructor() {
        super();
        this.blueprint = {};
    }

    /**
     * Initializes service with given blueprint
     *
     * @param {Object} options additional options
     * @param {Object} options.user the user object
     * @param {Function} options.require the require function for global sources
     * @param {Object} [options.event] the event object
     *
     * @returns {Object} the blueprint object
     */
    async init(options) {
        this.addBlueprint(DEFAULT_ROUTES, options);

        if (options.event) {
            await this.loadBlueprint(options);
        }

        return this.blueprint;
    }

    /**
     * Compiles a blueprint for the specified event.
     *
     * @param {string} eventId - The ID of the event.
     *
     * @returns {Promise} - A promise that resolves with the compiled blueprint.
     */
    async compile(eventId) {
        try {
            const compileUrl = COMPILE_BLUEPRINT_ENDPOINT.replace('{{eventId}}', eventId);
            return await this.post(compileUrl, {});
        } catch (error) {
            console.error(`[BlueprintService] Failed to compile blueprint for event ${eventId}`, error.message);
            console.error('[BlueprintService] The resulting blueprint may be incomplete and not reflecting latest changes.');
        }
    }

    /**
     * Fetches blueprint for given event and stores it in the service
     *
     * @param {Object} options additional options
     * @param {Object} options.event the event object
     * @param {Function} options.require the require function for global sources
     * @param {Object} [options.user] the user object
    *
     * @returns {Promise<Object>} blueprint
     */
    async loadBlueprint(options) {
        const eventId = options.event._id;
        await this.compile(eventId);
        const url = BLUEPRINT_DOCUMENT_ENDPOINT.replace('{{eventId}}', eventId);
        const { data: blueprint } = await this.get(url);

        if (!blueprint) {
            throw new Error(`Missing blueprint for event ${eventId}`);
        }

        this.addBlueprint(blueprint, options);
        await this.loadGlobalSources(options.require);

        return this.blueprint;
    }

    /**
     * Loads the global sources for the blueprint.
     *
     * @param {Function} require - The require function to use for loading the global sources.
     *
     * @returns {Promise} A promise that resolves when all global sources have been loaded.
     */
    async loadGlobalSources(require) {
        return Promise.all(this.getGlobalSourceUrls().map(url => require(url)));
    }

    /**
     * Retrieves the global source URLs from the blueprint modules.
     *
     * This method iterates over the modules in the blueprint and collects source URLs
     * from modules that are not blacklisted, have no controllers, and have a source URL.
     * The collected URLs are filtered to exclude any URLs that are blacklisted.
     *
     * @returns {Array} An array of global base dependency URLs.
     */
    getGlobalSourceUrls() {
        const globalBaseDependencies = [];

        for (const mod of Object.values(this.blueprint.modules)) {
            // in a case where we have a module with a sourceUrl
            // but no controllers, add the code urls to base deps
            const sourceUrl = mod.sourceUrl || [];

            if (!MODULES_BLACKLIST.includes(mod.name) && isEmpty(mod.controllers) && sourceUrl.length) {
                const urls = Array.isArray(sourceUrl) ? sourceUrl : [sourceUrl];
                globalBaseDependencies.push(...urls);
            }
        }

        return globalBaseDependencies;
    }

    /**
     * Get the blueprint modules for the current event,
     * where restricted modules and controllers have been removed
     *
     * @param {String} [type] if specified, returns modules of the given type
     * @returns {Object}
     */
    getModules(type) {
        const modules = this.blueprint.modules;
        const filtered = {};

        for (const [moduleKey, moduleDescriptor = {}] of Object.entries(modules)) {
            // if module have no type, it belongs the the event main navigation type
            const moduleType = moduleDescriptor.type || 'event';

            // filter according to type
            if (modules[moduleKey] && (!type || moduleType === type)) {
                filtered[moduleKey] = moduleDescriptor;
            }
        }

        return filtered;
    }

    /**
     * Retrieve the module given its name
     *
     * @param {String} name the name of the module
     * @returns {Object|undefined} the module or undefined
     */
    getModuleByName(name) {
        return (this.blueprint?.modules || {})[name];
    }

    /**
     * Checks if the given module is installed in the current event/
     *
     * @param {String} name the name of the module to check
     *
     * @returns {boolean} true if the module is installed
     */
    isModuleInstalled(name) {
        return Boolean(this.getModuleByName(name));
    }

    /**
     * Retrieve a controller from a module given its name
     *
     * @param {String} name the name of the module
     * @param {String} controller the name of the controller
     * @returns {Object|undefined} the controller or undefined
     */
    getControllerByName(name, controller) {
        const module = (this.blueprint.modules || {})[name] || {};
        return get(module, ['controllers', controller]);
    }

    /**
     * Given a module or controller this method checks if it matches with the current route.
     *
     * @param {Object} currentRoute the actual route.
     * @param {Object} currentSearchParams the actual search parameters
     * @param {Object} module the module or controller on which perform the lookup
     * @param {Boolean} [perfectMatch=false] if the match must be perfect or partial
     *
     * @return {Boolean} true if the link matches with the current route, false otherwise
     */
    matchRoute(currentRoute, currentSearchParams, module, perfectMatch = false) {
        // Set routes for this link if not set yet.
        if (!module.routes) {
            module.routes = this.getRoutesForModule(module);
        }

        // Look for first matching
        for (const route of module.routes) {
            // matches route and query params if the module defines those
            if (perfectMatch && currentRoute.originalPath === route &&
                    (!module.searchParams || isEqual(module.searchParams, currentSearchParams))) {
                return true;
            } else if (!perfectMatch && (currentRoute.originalPath || '').indexOf(route) === 0) {
                return true;
            }
        }

        return false;
    }

    /**
     * Given a blueprint or controller module this method returns the first relevant route.
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {String} the first relevant route or an empty string
     */
    getRouteForModule(module) {
        // Pick the default controller if defined or the first of the list.
        // On exploded menu, the passed module is controller
        const ctrl = this.getRelevantControllerForModule(module);

        // Route can be either an array or a string.
        // In former case pick the first.
        const route = Array.isArray(ctrl.route) ? ctrl.route[0] : ctrl.route;

        return route || '';
    }

    /**
     * Given a blueprint or controller module this method returns the first relevant controller.
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {Object} the first relevant controller or an empty object
     */
    getRelevantControllerForModule(module) {
        // Pick the default controller if defined or the first of the list.
        // On exploded menu, the link itself could define the controller.
        let ctrl = module.controllers ? module.controllers.default : module;

        if (!ctrl) {
            ctrl = sortByOrder(module.controllers)[0];
        }

        return ctrl || {};
    }

    /**
     * Given a blueprint module or controller this method returns all *unsorted* routes associated to it.
     *
     * @private
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {String[]} all available link's routes
     */
    getRoutesForModule(module) {
        const routes = [];

        for (const ctrl of this.getControllersForModule(module)) {
            // Route can be either an array or a string.
            if (Array.isArray(ctrl.route)) {
                routes.push(...ctrl.route);
            } else {
                routes.push(ctrl.route);
            }
        }
        return routes;
    }

    /**
     * Given a blueprint module or controller this method returns all its controllers sorted by `order`
     *
     * @private
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {Object[]} all available module's controllers
     */
    getControllersForModule(module) {
        if (!module.controllers) {
            return [module];
        }

        const ctrls = sortByOrder(module.controllers);

        return ctrls.filter(ctrl => !ctrl.bstgMenu);
    }

    /**
     * Generates relative link for given module
     *
     * @param {Object} module module from blueprint
     * @param {Object} params replacement parameters for route
     * @param {Object} query query parameters
     * @returns {String} relative link
     */
    getLinkForModule(module, params, query = {}) {
        let path = this.getRouteForModule(module);

        for (const param of Object.keys(params)) {
            path = path.replace(`:${param}`, params[param]);
        }

        if (!isEmpty(query)) {
            const queryString = Object.keys(query)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
                .join('&');

            path = `${path}?${queryString}`;
        }

        return path;
    }

    /**
     * Compares given array of modules with the one from current blueprint and
     * returns new controllers with `showOnInstall` flag.
     *
     * @param {Array<Object>} modules modules from blueprint
     * @returns {Array<Object>} new `showOnInstall` controllers
     */
    getShowOnInstallCtrls(modules) {
        const currentModules = this.getModules();
        const result = [];

        for (const moduleKey of Object.keys(currentModules)) {
            const module = currentModules[moduleKey];
            const showOnInstallCtrls = Object.values(module.controllers || {}).filter(ctrl => ctrl.showOnInstall);

            if (!showOnInstallCtrls.length) {
                continue;
            }

            const prevModule = modules[moduleKey] || {};

            for (const ctrl of showOnInstallCtrls) {
                const prevModuleCtrls = Object.values(prevModule.controllers || {});
                const prevCtrl = prevModuleCtrls.find(c => c.showOnInstall && c.controller === ctrl.controller);

                if (!prevCtrl) {
                    result.push(ctrl);
                }
            }
        }

        return result;
    }

    /**
     * Given an FP type and and Event ID, this method returns the path of
     * the page where we list that type of documents.
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the desired FP type
     *
     * @return {String} the path of the page where we list the given FP type
     */
    getFpTypeListingPath(eventId, fpType) {
        let listRoute = this.getFpTypeHref(eventId, fpType, 'list');
        if (!listRoute) {
            // Legacy support
            listRoute = this.buildUrl(FP_TYPE_LISTING_PATH, { eventId, fpType });
        }
        return listRoute;
    }

    /**
     * Retrieves the view path for a specific fpType and eventId.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the fp (e.g., 'type1', 'type2').
     * @param {object} [doc={}] - The document to link to.
     * @param {boolean} [partialAllowed=false] - Whether partial links are allowed.
     *
     * @returns {string|null} The view path for the specified fpType and eventId, or null if the module is not found.
     */
    getFpTypeViewPath(eventId, fpType, doc = {}, partialAllowed = false) {
        return this.getFpTypeHref(eventId, fpType, 'view', doc, partialAllowed);
    }

    /**
     * Generates a URL (href) for a specific fingerprint type and event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The fingerprint type.
     * @param {'list'|'create'|'view'|'import'|'custom'|'stat'} type - The type of the controller.
     * @param {Object} [doc={}] - Additional document parameters to include in the URL.
     * @param {boolean} [partialAllowed=false] - Whether partial URLs (containing '{{') are allowed.
     * @returns {string|null} The generated URL or null if the controller is not found or partial URLs are not allowed.
     */
    getFpTypeHref(eventId, fpType, type, doc = {}, partialAllowed = false) {
        const route = this.gtFpTypeRoute(fpType, type);
        if (!route) {
            return null;
        }

        const { meta: { module, controllerName } } = route;
        const href = getRouteHref(module, controllerName, { eventId, ...doc });
        return !partialAllowed && href.includes('{{') ? null : href;
    }

    /**
     * Retrieves the requested controller of a specific fp type.
     *
     * @param {string} fpType - The fp type to get controllers for.
     * @param {'list'|'create'|'view'|'import'|'custom'|'stat'} type - The type of the controller.
     *
     * @returns {Object|undefined} The controller object if found, otherwise undefined.
     */
    gtFpTypeRoute(fpType, type) {
        return this.getRoutesForFpType(fpType).find(ctrl => ctrl.meta.type === type);
    }

    /**
     * Retrieves controllers for a given fpType from the blueprint modules.
     *
     * @param {string} fpType - The fpType to filter controllers by.
     *
     * @returns {{ moduleName: string, type: string, controllerName: string, create?: Object, list?: Object, view?: Object, stat?: Object, custom?: Object, import?: Object }[]} An array of objects representing the controllers that match the given fpType.
     */
    getRoutesForFpType(fpType) {
        if (!fpType) {
            return [];
        }
        const routes = [];
        for (const module of Object.values(this.blueprint.modules)) {
            if (!module.controllers) {
                continue;
            }

            for (const [name, controller] of Object.entries(module.controllers)) {
                const resolverFpType = controller.resolvers?.default?.options?.fpType;
                const creationFpType = controller.routeOptions?.modelFpType;
                const routeFpType = controller.routeOptions?.crudOpts?.fpType;
                const importFpType = controller.routeOptions?.dataImportFpType;
                const statFpType = controller.routeOptions?.fpType;

                if (![resolverFpType, creationFpType, routeFpType, importFpType, statFpType].includes(fpType)) {
                    continue;
                }

                let type = 'custom';

                if (creationFpType) {
                    type = 'create';
                } else if (controller.controller === 'GenericViewItemCtrl' || controller.componentName === 'GenericForm') {
                    type = 'view';
                } else if (controller.componentName === 'GenericList' || controller.type === 'list') {
                    type = 'list';
                } else if (importFpType) {
                    type = 'import';
                } else if (statFpType) {
                    type = 'stat';
                }

                const route = this.controllerToRoute(module, name, { type });
                if (route) {
                    routes.push(route);
                }
            }
        }

        return routes;
    }

    /**
     * Converts a controller to a route configuration object.
     *
     * @param {Object} module - The module containing the controller.
     * @param {string} controllerName - The name of the controller.
     * @param {Object} [meta={}] - Additional metadata to include in the route.
     *
     * @returns {Object|null} The route configuration object or null if no path is found.
     * @property {string} name - The name of the route in the format `${module.name}.${controller.name}`.
     * @property {string} path - The path of the route.
     * @property {string} component - The component name to be used for the route.
     * @property {Object} meta - Metadata for the route including module and controller details.
     */
    controllerToRoute(module, controllerName, meta = {}) {
        const controller = module.controllers[controllerName];
        const [path] = Array.isArray(controller.route) ? controller.route : [controller.route];

        if (!path) {
            return null;
        }

        return {
            name: `${module.name}.${controllerName}`,
            path,
            component: controller.componentName || 'AngularPage',
            meta: { moduleName: module.name, controllerName, module, controller, ...meta }
        };

    }

    /**
     * Adds a blueprint to the existing blueprint after applying permissions, sanitizing names,
     * and remapping templates and controllers.
     *
     * @param {Object} blueprint - The blueprint object to be added.
     * @param {Object} [options] - Additional options to apply to the blueprint.
     * @param {Object} [options.event] - The event object.
     * @param {Object} [options.user] - The user object.
     *
     * @private
     */
    addBlueprint(blueprint, options) {
        // Sanitize and remap templates and controllers
        this.sanitizeNames(blueprint.modules);
        this.applyPermissions(blueprint, options);
        this.remapTemplatesAndControllers(blueprint);

        deepExtend(this.blueprint, blueprint);

        this.extendModules();
    }

    /**
     * Sanitizes the names of modules and their controllers.
     *
     * This function iterates over the provided modules and ensures that each module
     * and its controllers have appropriate titles. If a controller does not have a title,
     * it assigns the module's title or the controller's name as the title. Similarly, it
     * assigns the module's name to the module's title if it is not already set.
     *
     * @param {Object} modules - An object containing modules, where each module is an object
     *                           with a `controllers` property that is also an object.
     *
     * @private
     */
    sanitizeNames(modules) {
        for (const [moduleName, module] of Object.entries(modules)) {
            if (module?.controllers) {
                // Sanitize modules and controllers names
                for (const [ctrlName, controller] of Object.entries(module.controllers)) {
                    controller.title = controller.title || module.title || ctrlName;
                }
                module.name = moduleName;
                module.title = module.title || module.name;
            }
        }
    }

    /**
     * Applies permissions to the given blueprint object based on the event and user context.
     *
     * @param {Object} blueprint - The blueprint object to which permissions will be applied.
     * @param {Object} context - The context containing event and user information.
     * @param {Object} context.event - The event object containing event details.
     * @param {Object} context.user - The user object containing user details and roles.
     *
     * @returns {Object} - The modified blueprint object with applied permissions.
     *
     * @private
     */
    applyPermissions(blueprint, { event, user }) {
        Object.values(blueprint.modules).forEach(module => {
            if (module?.controllers) {
                this.removeUnaccessibles(module.controllers, user, event);
            }
        });

        this.removeUnaccessibles(blueprint.modules, user, event);

        return blueprint;
    }

    /**
     * Removes inaccessible controllers or modules from the given object based on the user's access rights and event.
     * If the user does not have access to a control or module, it is deleted from the object.
     * Additionally, if the call-to-actions should not be shown, they are removed from the route options.
     *
     * @param {Object} ctrlsOrModules - The object containing controllers or modules to be filtered.
     * @param {Object} user - The user object used to determine access rights.
     * @param {Object} [event] - The event object used to determine access rights.
     *
     * @private
     */
    removeUnaccessibles(ctrlsOrModules, user, event) {
        const showCta = this.shouldShowCta(user, event);

        Object.keys(ctrlsOrModules).forEach(name => {
            const ctrlOrModule = ctrlsOrModules[name];

            if (!ctrlOrModule || !this.hasAccess(ctrlOrModule, user, event)) {
                delete ctrlsOrModules[name];
            }

            if (!showCta && ctrlOrModule.routeOptions?.callToActions) {
                delete ctrlOrModule.routeOptions.callToActions;
            }
        });
    }

    /**
     * Determines whether the Call to Action (CTA) should be shown for a given event and user.
     *
     * @param {Object} user - The user object.
     * @param {Array<string>} user.roles - The roles assigned to the user.
     * @param {Object} [event] - The event object.
     * @param {string} [event._id] - The unique identifier of the event.
     * @param {string} [event.bs_owner_id] - The Backstage owner ID of the event.
     * @param {string} [event.ownerId] - The owner ID of the event.
     *
     * @returns {boolean} - Returns true if the user has permission to see the CTA for the event, otherwise false.
     *
     * @private
     */
    shouldShowCta(user, event) {
        if (!event) return false;
        const permissionContext = {
            event_id: event._id,
            org_id: event.bs_owner_id || event.ownerId
        };
        return hasPermission(user.roles, 'event_actions.show_cta', permissionContext);
    }

    /**
     * Checks if the user has access to a specific controller or module for a given event.
     *
     * @param {Object} ctrlOrModule - The controller or module to check access for.
     * @param {Object} user - The user whose access is being checked.
     * @param {Object} [event] - The event to check access against.
     *
     * @returns {boolean} - Returns true if the user has access, otherwise false.
     *
     * @private
     */
    hasAccess(ctrlOrModule, user, event) {
        const { canAccess, roles } = this.getAccessAndRoles(ctrlOrModule, user, event);
        return matchesRoles(event?._id, user, roles) && canAccess;
    }

    /**
     * Determines the access and roles for a given controller or module based on the user and event.
     *
     * @param {Object} ctrlOrModule - The controller or module containing roles, permissions, and feature flags.
     * @param {Object} user - The user object containing roles and organizations.
     * @param {Array} user.orgs - The organizations the user belongs to.
     * @param {Array} user.roles - The roles assigned to the user.
     * @param {Object} [event] - The event object, if applicable.
     *
     * @returns {{ canAccess: boolean, roles: object[] }} An object containing the access status and roles.
     * @returns {boolean} return.canAccess - Indicates if the user has access.
     * @returns {Array} return.roles - The roles associated with the controller or module.
     *
     * @private
     */
    getAccessAndRoles(ctrlOrModule, user, event) {
        let { roles, permission } = ctrlOrModule;
        if (!roles && ctrlOrModule.hideNavUnlessUserGlobalPermission) {
            roles = [{ globalRole: ctrlOrModule.hideNavUnlessUserGlobalPermission }];
        }

        let canAccess = true;
        let orgs = user.orgs;

        if (ctrlOrModule.featureFlag) {
            orgs = getOrgsByFeatureFlag(orgs, ctrlOrModule.featureFlag);
            canAccess = !!orgs.length;
        }

        if (permission && canAccess) {
            canAccess = orgs.some(org => {
                const context = { org_id: org._id };
                if (event) context.event_id = event._id;
                return hasPermission(user.roles, permission, context);
            });
        }

        return { canAccess, roles };
    }

    /**
     * Remaps the template URLs and controller names in the given blueprint object.
     *
     * This function iterates through the modules and controllers in the blueprint object,
     * and replaces the template URLs and controller names with the corresponding values
     * from the TEMPLATE_URL_MAPPING and CONTROLLER_NAME_MAPPING objects, if they exist.
     *
     * @param {Object} blueprint - The blueprint object containing modules and controllers.
     * @param {Object} [blueprint.modules] - The modules in the blueprint.
     * @param {Object} [blueprint.modules.controllers] - The controllers in each module.
     * @param {string} [blueprint.modules.controllers.templateUrl] - The template URL of the controller.
     * @param {string} [blueprint.modules.controllers.controller] - The name of the controller.
     *
     * @private
     */
    remapTemplatesAndControllers(blueprint = {}) {
        for (const mod of Object.values(blueprint.modules || {})) {
            for (const ctrl of Object.values(mod.controllers || {})) {
                if (TEMPLATE_URL_MAPPING[ctrl.templateUrl]) {
                    ctrl.templateUrl = TEMPLATE_URL_MAPPING[ctrl.templateUrl];
                }

                if (CONTROLLER_NAME_MAPPING[ctrl.controller]) {
                    ctrl.controller = CONTROLLER_NAME_MAPPING[ctrl.controller];
                }
            }
        }
    }

    /**
     * Extends the modules in the blueprint with additional extensions.
     *
     * This method retrieves all extensions for the blueprint's modules and attempts to extend each module with its corresponding extension.
     * If a module does not exist in the blueprint, a warning is logged and the extension is skipped.
     *
     * @method extendModules
     * @memberof BlueprintService
     *
     * @private
     */
    extendModules() {
        const extensions = this.getAllExtensions(this.blueprint.modules);

        for (const modules of extensions) {
            for (const [moduleName, extension] of Object.entries(modules)) {
                if (['order'].includes(moduleName)) {
                    // Skip reserved keywords
                    continue;
                }

                if (!this.blueprint.modules[moduleName]) {
                    console.warn('[BlueprintService] Trying to extend a module that is not in the blueprint:', moduleName);
                    continue;
                }

                deepExtendConcat(this.blueprint.modules[moduleName], extension);
            }
        }
    }

    /**
     * Filters the given modules to return only those that have controllers in their _extend property.
     *
     * @param {object} modules - The array of module objects to filter.
     *
     * @returns {Array} - An array of modules that have controllers in their _extend property.
     *
     * @private
     */
    getAllExtensions(modules) {
        return sortByOrder(Object.values(modules)
            .filter(module => get(module, '_extend.modules'))
            .map(module => module._extend.modules));
    }
}
