"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var _attachments = require("libs/utils/attachments");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var _require = require('lodash'),
  get = _require.get,
  isBoolean = _require.isBoolean,
  isEmpty = _require.isEmpty;
module.exports = /* @ngInject */["$route", "$routeParams", "$scope", "metadataService", "eventService", "bulkUpdateService", "appScriptService", "crudItems", "clipboardService", "customActionButtonsService", "essenceManager", "userService", function ($route, $routeParams, $scope, metadataService, eventService, bulkUpdateService, appScriptService, crudItems, clipboardService, customActionButtonsService, essenceManager, userService) {
  var eventId, getAssetAttachmentContentType;
  var event = eventService.decorateScope(eventId = $routeParams.eventId);
  var modelId = $routeParams.modelId;
  var currentRouteDescriptor = ($route.current || {}).$$route;
  if (!currentRouteDescriptor) {
    return;
  }
  var crudOpts = currentRouteDescriptor.crudOpts || {};
  var modelFpType = $scope.modelFpType = crudOpts.fpType;
  var modelIconClass = ((currentRouteDescriptor.blueprintMetadata || {}).module || {}).icon;
  $scope.modelIconClass = modelIconClass;
  var metadataForType = metadataService.getCachedMetadataForTypeAsArray(eventId, modelFpType);
  var allRequiredFields = metadataForType.filter(function (field) {
    return get(field, 'validations.required');
  }).map(function (field) {
    return field.field;
  });

  // add context for roles
  var _iterator = _createForOfIteratorHelper(metadataForType),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var field = _step.value;
      if (Array.isArray(field.roles)) {
        field.roles = field.roles.map(function (role) {
          if (role.orgRole) {
            return _objectSpread({
              orgId: event.ownerId
            }, role);
          } else if (role.eventRole) {
            return _objectSpread({
              eventId: eventId
            }, role);
          } else {
            return role;
          }
        });
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  metadataForType = userService.filterByRoles(metadataForType);

  // filter out fields hidden for users invited by accredited link without proper event role
  if (userService.isInvitedByLink(event)) {
    var forbiddenFieldTypes = ['external', 'nested-object', 'list', 'custom'];
    metadataForType = metadataForType.filter(function (field) {
      return !get(field, 'hideForInvited') && !(get(field, 'is_custom_field') && forbiddenFieldTypes.includes(get(field, 'kind')));
    });
  }
  var metadataFieldsToFillIfEmpty = metadataService.findFieldsWithFlag(metadataForType, 'generate_value_if_empty');

  // only let one targets or exceptions filter through because the single widget caters for both
  $scope.fields = metadataService.filterDuplicateTargetsExceptionsFields(metadataForType);
  var modelList = (crudItems || {}).data;
  // Some hack since this controller was initially designed to expect a list of items instead of a single element...
  var isArray = modelList.fp_type === undefined;
  var model = isArray ? _.findWhere(_.compact(modelList), {
    _id: modelId
  }) : modelList;
  var originalModel;
  function setModel(doc) {
    $scope.model = doc;
    originalModel = _.clone(doc);
  }
  if (!model) {
    BSTG.services.documents.getDocById(eventId, modelId).then(setModel);
  }
  setModel(model);

  // check if all required fields are shown to user or set in original model
  var hiddenRequiredFields = _.difference(allRequiredFields, metadataForType.map(function (f) {
    return f.field;
  }));
  $scope.hasHiddenRequiredFields = hiddenRequiredFields.some(function (field) {
    return isEmpty(originalModel[field]);
  });
  $scope.clipboard = new clipboardService($scope.model.fp_ext_id);
  $scope.customActionButtons = customActionButtonsService.init(currentRouteDescriptor.customActionButtons || {}, eventId, model);
  $scope.isActionButtonsPanelShown = !_.isEmpty($scope.customActionButtons);
  essenceManager.getPreviewElements(model).then(function (previewElements) {
    $scope.previewElements = previewElements;
  });
  $scope.saveModel = function () {
    var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    $scope.saveInProgress = true;

    // the main form is not perceiving the errors from the child forms
    // and therefore the submission was being done even if there were
    // validation errors. doesn't seem easy to overcome this overcomplicated
    // form, so now we are validating again for the required fields before submitting
    var requiredFields = _.chain($scope.fields).filter(function (field) {
      return get(field, 'validations.required');
    }).map(function (field) {
      return [field.field, field];
    }).object().value();
    var someAreInvalid = Object.keys(requiredFields).some(function (field) {
      if (['timestamp', 'number'].includes(requiredFields[field].kind)) {
        var _$scope$model;
        /**
         * Because of the way Rome.JS handles the input, even if kind timestamp is set,
         * a string is returned. In this case we still need to check if it's at least populated.
         *
         * @see https://github.com/bevacqua/rome
         */
        if (typeof ((_$scope$model = $scope.model) === null || _$scope$model === void 0 ? void 0 : _$scope$model[field]) === 'string') {
          return $scope.model[field].length === 0;
        } else {
          return !Number.isFinite($scope.model[field]);
        }
      } else if (requiredFields[field].kind === 'boolean') {
        return !isBoolean($scope.model[field]);
      } else {
        return isEmpty($scope.model[field]);
      }
    });
    if (!_.isEmpty($scope.form.$error) || someAreInvalid) {
      $scope.saveInProgress = false;
      $scope.setFlashMessage('The item could not be saved because some fields are invalid.', 'danger');
      return;
    }
    var ignoreProtectedFields;
    var opts = {
      fieldsToFillIfEmpty: metadataFieldsToFillIfEmpty
    };
    if (bulkUpdateService.doProtectedFieldsDiffer(originalModel, model)) {
      ignoreProtectedFields = opts.ignoreProtected = confirm('You have made changes in protected fields. ' + 'These data were set by the user. Overwrite these?');
    }
    return bulkUpdateService.updateDoc(eventId, event.node, modelFpType, model, opts).then(function (response) {
      if (_.isFunction(model.afterSave)) {
        return model.afterSave().then(function () {
          return response;
        });
      }
      if (currentRouteDescriptor.onAfterModelSaveAppScript) {
        var data = response.data;
        return appScriptService.callAppScript(currentRouteDescriptor.onAfterModelSaveAppScript, {
          model_id: !_.isEmpty(data.upsertedDocs) ? data.upsertedDocs[0].id : model._id,
          changed: !_.isEmpty(data.upsertedDocs)
        }).then(function () {
          return response;
        });
      }
      return response;
    }).then(function (response) {
      $scope.setFlashMessage('This item has been saved.');

      // take care of our view of protected field value state
      if (originalModel.fp_protected) {
        originalModel.fp_protected.forEach(function (field) {
          if (ignoreProtectedFields) {
            // update original model with new protected field values
            return originalModel[field] = model[field];
          } else {
            // restore values back to their originals
            return model[field] = originalModel[field];
          }
        });
      }
      $scope.saveInProgress = false;
      return response;
    }, function (err) {
      var message = 'The item could not be saved.';
      if (err.data && typeof err.data.error === 'string') {
        message = err.data.error;
      } else if (typeof err.error === 'string') {
        message = err.error;
      }
      var parsedErrors = message.match(/fp_ext_id:[^:]*:(.*)/);
      if (parsedErrors) {
        message = parsedErrors[1];
      }
      $scope.saveInProgress = false;
      $scope.setFlashMessage(message, 'danger');
    });
  };
  $scope.shouldShowImageAssetPreview = function (model) {
    var contentType = getAssetAttachmentContentType(model);
    var t = contentType.substring(0, contentType.indexOf('/'));
    return t === 'image';
  };
  $scope.getLabelForModel = function (model) {
    var m = model;
    var l = metadataService.getReprLabelForItem(eventId, m, true);
    return l || m.name || m.title || m.subject || m.fp_ext_id || m._id || '(untitled)';
  };
  $scope.getIdealAssetDownloadFilename = function (fpAssetPath) {
    return fpAssetPath.substring(fpAssetPath.indexOf('.') + 1, fpAssetPath.indexOf('/')) + fpAssetPath.substring(fpAssetPath.lastIndexOf('.'));
  };
  getAssetAttachmentContentType = $scope.getAssetAttachmentContentType = function (model) {
    var attachments = (0, _attachments.getAttachmentNames)(model);
    return ((0, _attachments.getAttachmentMetadata)(model, attachments[0]) || {}).content_type;
  };
}];