// Classes
import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * @const {number} MAX_MEETINGS_PER_PAGE the maximum number of items to load per page
 * @private
 */
const MAX_MEETINGS_PER_PAGE = 25;

/**
 * Meeting request participant invite url API endpoint
 * @constant {string} MEETING_REQUEST_PARTICIPANT_INVITE_URL_ENDPOINT
 * @private
 */
const MEETING_REQUEST_PARTICIPANT_INVITE_URL_ENDPOINT =
    `${API_BASE_PATH}/events/{{eventId}}/meeting-request/{{meetingRequestId}}/p/{{participantId}}/inviteUrl`;

export default class MeetingsService extends BaseService {

    get MAX_MEETINGS_PER_PAGE() {
        return MAX_MEETINGS_PER_PAGE;
    }

    /**
     * Get all meetings.
     *
     * TEMPORARY: This method is a temporary workaround to call the app script directly.
     *
     * @param {string} eventId
     * @param {object} services
     * @param {object} params
     *
     * @returns {Promise<object>}
     */
    async getAll(eventId, services, params) {
        const { crudOpts, page, sortField, sort, search } = params;
        const { rows, total_rows } = await services.appScripts.callAppScript(eventId, crudOpts.appscriptUrl, {
            skip: (page - 1) * this.MAX_MEETINGS_PER_PAGE,
            reverse: sort !== 'ASC',
            sort: sortField,
            search,
            limit: this.MAX_MEETINGS_PER_PAGE
        });

        return { rows, total: total_rows };
    }

    /**
     * Get participant invite url for a meeting request
     *
     * @param {string} eventId
     * @param {string} meetingRequestId
     * @param {string} participantId
     * @returns {Promise<{inviteUrl: string}>}
     */
    async getMeetingRequestParticipantInviteUrl(eventId, meetingRequestId, participantId) {
        const url = this.buildUrl(MEETING_REQUEST_PARTICIPANT_INVITE_URL_ENDPOINT, { eventId, meetingRequestId, participantId });
        const { data } = await this.get(url);
        return data;
    }
}
