// Utils
import { get, extend } from 'lodash';
import { unixToDate } from 'libs/utils/time';

// Constants
import { DEFAULT_DATETIME_FORMAT } from 'libs/utils/constants';

/**
 * Decorates the control with kind-specific attributes based on the control's kind.
 *
 * @param {FieldDescriptor} control the control object.
 * @param {ControlConfig} config the configuration object.
 * @param {object} attrs the attributes object to be decorated.
 */
export function getKindSpecificAttributes(control, config, attrs) {
    switch (control.kind) {
        case 'timestamp':
            decorateTimestampAttributes(control, config, attrs);
            break;
        case 'formatted-datetime':
            attrs['value-type'] = 'format';
            break;
        case 'editorial':
            decorateEditorialAttributes(control, config, attrs);
            break;
        case 'number':
            attrs.type = 'number';
            break;
        case 'boolean':
            decorateBooleanAttributes(control, attrs);
            break;
        case 'colour':
            attrs.context = config.context;
            break;
        case 'video-call':
            decorateVideoCallAttributes(control, config, attrs);
            break;
        case 'list':
        case 'nested-object':
            attrs.event = config.event;
            break;
        case 'external':
            decorateExternalAttributes(control, config, attrs);
            break;
        case 'targets-exceptions':
            decorateTargetsExceptionsAttributes(control, config, attrs);
            break;
        case 'custom':
        case 'content-page':
            decorateCustomContentPageAttributes(control, config, attrs);
            break;
        case 'display-only':
            attrs.disabled = true;
            break;
        default:
            Object.assign(attrs, control.kind_options || {});
            break;
    }
}

/**
 * Decorates the given attributes object with timestamp-related properties.
 *
 * @param {Object} control - The control object containing metadata for the timestamp.
 * @param {Object} config - The configuration object containing event and localization information.
 * @param {Object} attrs - The attributes object to be decorated with timestamp properties.
 */
function decorateTimestampAttributes(control, config, attrs) {
    Object.assign(attrs, {
        'format': DEFAULT_DATETIME_FORMAT,
        'minute-step': 10,
        'show-second': false,
        'timezone': config.event.timezone,
        'tip': control.tooltip || config.$i18n.t('generic_form.types.timestamp'),
        'type': 'datetime',
        'value-type': 'X'
    });

    if (typeof config.value === 'number' && config.value > 9999999999) {
        attrs.valueType = 'x';
    }
}

/**
 * Decorates the given control with editorial attributes.
 *
 * @param {Object} control - The control to be decorated.
 * @param {Object} config - Configuration object containing event and parentModel.
 * @param {Object} attrs - Attributes object to be modified.
 */
function decorateEditorialAttributes(control, config, attrs) {
    attrs.event = config.event;
    attrs.model = config.parentModel;
    delete attrs.legacy;
}

/**
 * Decorates a control's attributes with boolean-specific labels.
 *
 * @param {Object} control - The control object containing label or field information.
 * @param {Object} attrs - The attributes object to be decorated.
 * @param {string} [control.label] - The label of the control.
 * @param {string} [control.field] - The field name of the control.
 * @param {string} [attrs.onLabel] - The label to be used when the control is "on".
 * @param {string} [attrs.offLabel] - The label to be used when the control is "off".
 */
function decorateBooleanAttributes(control, attrs) {
    const label = control.label || control.field;
    attrs.onLabel = label;
    attrs.offLabel = label;
    delete attrs.label;
}

/**
 * Decorates the attributes for a video call control.
 *
 * @param {Object} control - The control object to be decorated.
 * @param {Object} config - The configuration object containing the necessary data.
 * @param {Object} config.parentModel - The parent model for the session.
 * @param {Object} config.settings - The settings for the video call.
 * @param {Object} config.event - The event associated with the video call.
 * @param {Object} attrs - The attributes object to be decorated.
 */
function decorateVideoCallAttributes(control, config, attrs) {
    attrs.session = config.parentModel;
    attrs.settings = config.settings;
    attrs.event = config.event;
}

/**
 * Decorates the given attributes object with additional properties based on the control and config objects.
 *
 * @param {Object} control - The control object containing kind options.
 * @param {Object} control.kind_options - The kind options of the control.
 * @param {string} control.kind_options.type - The type of the control.
 * @param {boolean} [control.kind_options.showCreateButton] - Flag indicating whether to show the create button.
 * @param {Object} config - The configuration object.
 * @param {Object} [config.event] - The event object.
 * @param {string} [config.event._id] - The ID of the event.
 * @param {Object} attrs - The attributes object to be decorated.
 * @param {Array} attrs.options - The options array to be initialized.
 * @param {string} attrs.fpType - The type of the control to be set.
 * @param {string} attrs.eventId - The event ID to be set.
 * @param {boolean} [attrs.showCreateButton] - Flag indicating whether to show the create button.
 */
function decorateExternalAttributes(control, config, attrs) {
    attrs.eventId = config.event?._id;
}

/**
 * Decorates the attributes of the target with specific control and configuration settings.
 *
 * @param {Object} control - The control object to be used for decoration.
 * @param {Object} config - The configuration object containing event details.
 * @param {Object} attrs - The attributes object to be decorated.
 * @property {string} attrs.eventId - The ID of the event from the configuration.
 * @property {boolean} attrs.expandable - Indicates if the target is expandable.
 * @property {boolean} attrs.openIfHasRules - Indicates if the target should be open if it has rules.
 */
function decorateTargetsExceptionsAttributes(control, config, attrs) {
    attrs.eventId = config.event?._id;
    attrs.expandable = true;
    attrs.openIfHasRules = false;
}

/**
 * Decorates the attributes of a custom content page control.
 *
 * @param {Object} control - The control to be decorated.
 * @param {Object} config - The configuration object containing the parent model and event.
 * @param {Object} attrs - The attributes object to be decorated.
 * @param {Object} attrs.model - The model to be set on the attributes.
 * @param {Object} attrs.event - The event to be set on the attributes.
 */
function decorateCustomContentPageAttributes(control, config, attrs) {
    attrs.model = config.parentModel;
    attrs.event = config.event;
}

/**
 * Decorates a control with control options attributes.
 *
 * @param {FieldDescriptor} control the control object to decorate.
 * @param {ControlConfig} config the configuration object.
 * @param {object} attrs the attributes object to decorate.
 */
export function getKindOptionsAttributes(control, config, attrs) {
    const options = control.kind_options;
    if (!options) return;

    handleValues(options, attrs);
    handleFields(options, attrs);
    handleListStyle(options, attrs);
    handleFormat(options, attrs);
    handleIdField(options, attrs);
    handleValuesOrder(options, attrs);
    handleTextMultiline(control, options, attrs);
    handleTimestamp(control, options, config, attrs);
    handleFormattedDatetime(control, options, attrs);
    handleList(control, options, attrs);
    handleExternal(control, options, attrs);
    handleNestedObject(control, options, attrs);
}

/**
 * Processes the given options and updates the attrs object with formatted options and a trackBy property.
 *
 * @param {Object} options - The options object containing values to be processed.
 * @param {Object} options.values - An object where keys are the option values and values are the option labels.
 * @param {Object} attrs - The attributes object to be updated with formatted options and trackBy property.
 */
function handleValues(options, attrs) {
    const { values } = options;
    if (values) {
        attrs.options = Object.keys(values).map(v => ({ label: values[v], value: v }));
        attrs.trackBy = 'value';
    }
}

/**
 * Handles the fields and options attributes based on the provided options.
 *
 * @param {Object} options - The options object that may contain fields or values.
 * @param {Object} attrs - The attributes object to be modified.
 * @param {Array} [options.fields] - An optional array of fields to be assigned to attrs.fields.
 * @param {Object} [options.values] - An optional object of values. If not present, options will be assigned to attrs.options.
 */
function handleFields(options, attrs) {
    if (options.fields) {
        attrs.fields = options.fields;
    } else if (!options.values) {
        attrs.options = options;
    }
}

/**
 * Modifies the `attrs` object based on the `options` provided.
 *
 * If `options.list_style` is 'select', sets `attrs.trackBy` to 'value'.
 *
 * @param {Object} options - The options object.
 * @param {string} options.list_style - The style of the list.
 * @param {Object} attrs - The attributes object to be modified.
 */
function handleListStyle(options, attrs) {
    if (options.list_style === 'select') {
        attrs.trackBy = 'value';
    }
}

/**
 * Handles the formatting of attributes based on the provided options.
 *
 * @param {Object} options - The options object that may contain a format property.
 * @param {Object} attrs - The attributes object that will be modified.
 */
function handleFormat(options, attrs) {
    if (options.format) {
        attrs.format = options.format;
        delete attrs.options;
    }
}

/**
 * Handles the id field in the given options and modifies the attrs object accordingly.
 *
 * @param {Object} options - The options object containing the id field.
 * @param {string} options.id_field - The id field to be handled.
 * @param {Object} attrs - The attributes object to be modified.
 * @param {string} [attrs.emitKey] - The key to emit.
 * @param {string} [attrs.valueKey] - The key for the value.
 */
function handleIdField(options, attrs) {
    if (options.id_field) {
        attrs.emitKey = options.id_field;
        attrs.valueKey = ['fp_asset', '_id'].includes(options.id_field) ? 'ids' : `${options.id_field}s`;
        delete attrs.options;
    }
}

/**
 * Sorts the `attrs.options` array based on the order specified in `options.values_order`.
 *
 * @param {Object} options - The options object containing the values order.
 * @param {Object} options.values_order - An object mapping values to their order.
 * @param {Object} attrs - The attributes object containing the options array to be sorted.
 * @param {Array} attrs.options - The array of options to be sorted.
 */
function handleValuesOrder(options, attrs) {
    const { values_order } = options;
    if (values_order) {
        attrs.options.sort((a, b) => values_order[a.value] - values_order[b.value]);
    }
}

/**
 * Handles the attributes for a multiline text control.
 *
 * @param {Object} control - The control object.
 * @param {string} control.kind - The kind of control.
 * @param {Object} options - The options to extend the attributes with.
 * @param {Object} attrs - The attributes to be modified.
 */
function handleTextMultiline(control, options, attrs) {
    if (control.kind === 'text-multiline') {
        extend(attrs, options);
        delete attrs.options;
    }
}

/**
 * Handles the timestamp control by setting appropriate attributes based on the provided options and configuration.
 *
 * @param {Object} control - The control object containing information about the type of control.
 * @param {Object} options - The options object containing settings for the control.
 * @param {Object} config - The configuration object containing settings and parent model data.
 * @param {Object} attrs - The attributes object to be modified based on the control and options.
 */
function handleTimestamp(control, options, config, attrs) {
    if (control.kind === 'timestamp') {
        if (options.timeInterval) {
            const interval = get(config.settings, options.timeInterval.replace(/^settings\./, ''));
            if (interval) {
                attrs.minuteStep = interval / 60;
            }
        }

        const notBefore = config.parentModel[options.not_before];
        const notAfter = config.parentModel[options.not_after];

        if (notBefore || notAfter) {
            attrs.notBefore = unixToDate(notBefore).toDate();
            attrs.notAfter = unixToDate(notAfter).toDate();

            if (isNaN(attrs.notBefore.getTime())) {
                delete attrs.notBefore;
            }

            if (isNaN(attrs.notAfter.getTime())) {
                delete attrs.notAfter;
            }
        }
    }
}

/**
 * Handles the formatting of datetime controls based on the provided options and attributes.
 *
 * @param {Object} control - The control object containing the kind of control.
 * @param {Object} options - The options object containing formatting and enabling options.
 * @param {boolean} options.dates_enabled - Flag indicating if dates are enabled.
 * @param {boolean} options.times_enabled - Flag indicating if times are enabled.
 * @param {string} [options.date_format] - The format string for dates.
 * @param {string} [options.time_format] - The format string for times.
 * @param {Array} [options.dates] - An array containing the start and end dates.
 * @param {Array} [options.times] - An array containing the start and end times.
 * @param {Object} attrs - The attributes object to be modified based on the options.
 * @param {string} [attrs.type] - The type attribute to be set (date, time, or datetime).
 * @param {string} [attrs.format] - The format attribute to be set.
 * @param {string} [attrs.notBefore] - The notBefore attribute to be set for dates.
 * @param {string} [attrs.notAfter] - The notAfter attribute to be set for dates.
 * @param {string} [attrs.notBeforeTime] - The notBeforeTime attribute to be set for times.
 * @param {string} [attrs.notAfterTime] - The notAfterTime attribute to be set for times.
 */
function handleFormattedDatetime(control, options, attrs) {
    if (control.kind === 'formatted-datetime') {
        if (options.dates_enabled && !options.times_enabled) {
            attrs.type = 'date';
            attrs.format = options.date_format;
        } else if (options.times_enabled && !options.dates_enabled) {
            attrs.type = 'time';
            attrs.format = options.time_format;
        } else {
            attrs.type = 'datetime';
            const format = `${options.date_format || ''} ${options.time_format || ''}`;
            attrs.format = format.trim();
        }

        if (options.dates?.length && options.dates_enabled) {
            attrs.notBefore = options.dates[0];
            attrs.notAfter = options.dates[1];
        }

        if (options.times?.length && options.times_enabled) {
            attrs.notBeforeTime = options.times[0];
            attrs.notAfterTime = options.times[1];
        }
    }
}

/**
 * Handles the attributes for a control of kind 'list'.
 *
 * @param {Object} control - The control object.
 * @param {string} control.kind - The kind of the control.
 * @param {Object} options - The options object.
 * @param {string} options.item_kind - The kind of the item.
 * @param {Object} attrs - The attributes object to be modified.
 */
function handleList(control, options, attrs) {
    if (control.kind === 'list') {
        attrs.itemType = options.item_kind;
    }
}

/**
 * Handles the attributes for external controls.
 *
 * @param {Object} control - The control object.
 * @param {string} control.kind - The kind of control.
 * @param {Object} options - The options object.
 * @param {boolean} options.single_doc - Indicates if only a single document is allowed.
 * @param {Object} attrs - The attributes object to be modified.
 */
function handleExternal(control, options, attrs) {
    if (control.kind === 'external') {
        attrs.multiple = !options.single_doc;
        attrs.fpType = options.type;
        attrs.options = [];

        if (options.preview) {
            attrs.preview = true;
        }

        if (options.showCreateButton) {
            attrs.showCreateButton = true;
        }
    }
}

/**
 * Handles the attributes for a nested object control.
 *
 * @param {Object} control - The control object.
 * @param {string} control.kind - The kind of control.
 * @param {Object} options - The options object.
 * @param {boolean} [options.skip_save] - Flag to skip saving.
 * @param {Object} attrs - The attributes object to be modified.
 * @param {boolean} attrs.skipSave - Flag indicating whether to skip saving.
 */
function handleNestedObject(control, options, attrs) {
    if (control.kind === 'nested-object') {
        attrs.skipSave = options.skip_save || false;
    }
}
